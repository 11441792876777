import axios from "axios";
let apiUrl = "http://127.0.0.1:8000";
apiUrl = "https://api.neurofeedbackhypnosemarseille.fr";
// const instance = axios.create({
//     baseURL: apiUrl,
//     headers: {'X-Custom-Header': 'foobar'}
//   });

// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// instance.defaults.xsrfCookieName = "csrftoken";
// let csrftoken = '{{ csrf_token }}'
// console.log(axios.defaults.xsrfCookieName);


export default class ServicesApi {


    static getServices(): Promise<any | null> {

        return axios.get(`${apiUrl}/services/`)
            .then((res) => (this.isEmpty(res) ? null : res.data))
            .catch((error) => this.handleError(error));
    }

    static getPosts(): Promise<any | null> {

        
        return axios.get(`${apiUrl}/services-posts/`)

            .then((res) => (this.isEmpty(res) ? null : res.data))
            .catch((error) => this.handleError(error));
    }

    static getPost(id:any): Promise<any | null> {

        
        return axios.get(`${apiUrl}/services-posts/${id}`)

            .then((res) => (this.isEmpty(res) ? null : res.data))
            .catch((error) => this.handleError(error));
    }



    static getTestimony(): Promise<any | null> {

        return axios.get(`${apiUrl}/contact/testimony`)

        .then((res) => (this.isEmpty(res) ? null : res.data))
        .catch((error) => this.handleError(error));

    }



    
    // static addHeart(data:any):Promise<any | null> {
    //     let heart = data.heart + 1
    //     let datat = {title:data.title, content:data.content,heart:heart}
    //     const CSRF_TOKEN= csrftoken
    //     console.log(CSRF_TOKEN);
        
    //     return axios({
    //         method:'PUT',
    //         url:`${apiUrl}/services-posts/${data.id}/`,
    //         headers:{
    //             // 'X-CSRF-Token':CSRF_TOKEN,
    //             //'Access-Control-Allow-Origin':'*',
    //             // 'Accept': 'application/json',
    //             // 'Content-Type': 'application/json',
    //         },
    //         data:{
    //             datat
    //         }
    //     })
    //     .then((res) => (this.isEmpty(res) ? null : res.data))
    //     .catch((error) => this.handleError(error));
    // }



    static addHeart(data:any):Promise<any | null> {
        let heart = data.heart + 1
        let datat = {title:data.title, content:data.content,heart:heart}
        return axios.post(`${apiUrl}/services/${data.id}/`,datat )
        .then((res) => (this.isEmpty(res) ? null : res.data))
        .catch((error) => this.handleError(error));
    }

    // static addHeart(data:any):Promise<any | null> {
    //     let heart = data.heart + 1
    //     let datat = {title:data.title, content:data.content,heart:heart}

    //     return axios.put(`${apiUrl}/services-posts/${data.id}/`,datat )
    //     .then((res) => (this.isEmpty(res) ? null : res.data))
    //     .catch((error) => this.handleError(error));
    // }
    // static sendMessage(contact:any): Promise<any | null> {
        
    //     console.log(contact);
    //     const t = false
    //     return new Promise(function(resolve, reject){
    //         if (t) {
    //             resolve("There is a count value.");
    //         } else {
    //             reject("There is no count value");
    //         }
        

    //    });
    // }


    static sendMessage(contact:any): Promise<any | null> {
        
        return axios.post(`${apiUrl}/contact/send-message`, contact)
        .then((res) => {
            console.log(res);
            return this.isEmpty(res) ? null : res.data
        })
        .catch((error) => this.handleError(error));  
    }



    static isEmpty(data: Object): boolean {
        return Object.keys(data).length === 0;
    }
    static handleError(error: Error): void {
        console.error(error);
    }

}

